(function($) {



/********************
    PARALLAX GRID
*********************/


// Scroll class

  var latestKnownScrollY = window.pageYOffset || 0,
      scrollDebounce = false,
      scrollListeners = [];

      //console.log(latestKnownScrollY);

  function resetScrollDebounce() {

    scrollDebounce = false;

  }

  function onScroll() {

    if(scrollDebounce) {
      return;
    }

    requestAnimationFrame(resetScrollDebounce);

    latestKnownScrollY = window.pageYOffset; // No IE8

    for (var i = scrollListeners.length - 1; i >= 0; i--) {
      scrollListeners[i]({
        latestKnownScrollY
      });
    }

    scrollDebounce = true;

  }

  window.addEventListener('scroll', onScroll, { passive: false });


  class ScrollSection {

    constructor(element, { onInView, onOutOfView, onScroll, threshold = 0 }) {

      this.el = element;

      this.onInView = onInView;
      this.onOutOfView = onOutOfView;
      this.onScroll = onScroll;

      this.observer = new IntersectionObserver((e) => this.intersectionObserver(e), {
        threshold
      });
      this.observer.observe(this.el);

    }

    intersectionObserver([event]) {

      if (event.intersectionRatio > 0) {
        this.inView(event);
        return;
      }

      this.outOfView(event);

    }

    inView(event) {

      if(this.onInView) {
        this.onInView(event);
      }

      if(this.onScroll) {
        this.onScroll({
          latestKnownScrollY
        });

        scrollListeners.push(this.onScroll);

      }

    }

    outOfView(event) {

      if(this.onOutOfView) {
        this.onOutOfView(event);
      }

      if(this.onScroll) {

        scrollListeners = scrollListeners.filter(item => {
          return item != this.onScroll;
        });
      }

    }

    get relativeScrollY() {
      return latestKnownScrollY - this.el.offsetTop;
    }

  }





var grid = document.querySelector(".thumb-grid");

if (grid) {
  var templateColumns = grid.querySelectorAll(".thumb-grid-col"),
      duration = grid.clientHeight,
      columnSpeed = [-.6, -.4, -.7, -.5, -.65, -.35, -.55],
      columnCount = templateColumns.length,
      multiplier = 4;

      //console.log(duration + 'c= ' + columnCount);

  var templateSection = new ScrollSection(grid, {
    onScroll: morphTemplate
  });

  function morphTemplate() {

    var progress = 1 - Math.max((duration - templateSection.relativeScrollY) / duration, 0);

    for (var i = columnCount - 1; i >= 0; i--) {
      templateColumns[i].style.transform = `translate(0, calc(${progress * multiplier * columnSpeed[i]}em))`;
    }

  }
}







$(document).ready(function(){
  $('.main-carousel').flickity({
    // options
    cellAlign: 'left',
    contain: true,
    imagesLoaded: true, 
    setGallerySize: true, 
    pageDots: false, 
    prevNextButtons: false,
    lazyLoad:0,
  });
});

//Flickity buttons
// previous
$('.button--previous').on( 'click', function() {
  $(this).parent().find('.main-carousel').flickity('previous');
});
$('.button--next').on( 'click', function() {
  $(this).parent().find('.main-carousel').flickity('next');
});




$('.home-carousel').flickity({
  // options
  cellAlign: 'center',
  contain: true,
  imagesLoaded: true, 
  //setGallerySize: true, 
  //pageDots: true, 
  prevNextButtons: true,
  wrapAround:true,
  autoPlay:3000,
  pauseAutoPlayOnHover: false,
  lazyLoad:2,
});

//Flickity buttons
// previous
$('.button--previous').on( 'click', function() {
  $(this).parent().find('.home-carousel').flickity('previous');
});
$('.button--next').on( 'click', function() {
  $(this).parent().find('.home-carousel').flickity('next');
});






// New Carousel

// autoplay
/*var timer = setInterval("nextSlide()",5000);
$(".carousel").hover(function(){
    clearInterval(timer);
},
function(){
    timer = setInterval("nextSlide()",5000);
});*/

// show next
var nextSlide = function (){
    $('.arrow-control-next').off('click');

    $('.carousel-transition').removeClass('carousel-reverse');
    $('.at-bat').removeClass('at-bat').addClass('last-up-temp');
    $('.on-deck').removeClass('on-deck').addClass('at-bat');
    $('.in-the-hole').removeClass('in-the-hole').addClass('on-deck');

    $('.deep-field').removeClass('deep-field').addClass('in-the-hole');

    $('.last-up').removeClass('last-up').addClass('deep-field');
    $('.last-up-temp').removeClass('last-up-temp').addClass('last-up').delay(800).queue(function(next) {
        next(); 
        $('.arrow-control-next').on('click', nextSlide);
    });
}
$('.arrow-control-next').on('click', nextSlide);

// show previous
var prevSlide = function (){
    $('.arrow-control-prev').off('click');

    $('.carousel-transition').addClass('carousel-reverse');
    $('.on-deck').removeClass('on-deck').addClass('in-the-hole temp');
    $('.at-bat').removeClass('at-bat').addClass('on-deck');
    $('.last-up').removeClass('last-up').addClass('at-bat');

    $('.deep-field').removeClass('deep-field').addClass('last-up');

    $('.in-the-hole').not('.temp').removeClass('in-the-hole').addClass('deep-field');
    $('.temp').removeClass('temp').delay(800).queue(function(next) {
        next(); 
        $('.arrow-control-prev').on('click', prevSlide);
    });
}
$('.arrow-control-prev').on('click', prevSlide);








/********************
    PARALLAX HERO
*********************/


// Create cross browser requestAnimationFrame method:
window.requestAnimationFrame = window.requestAnimationFrame
 || window.mozRequestAnimationFrame
 || window.webkitRequestAnimationFrame
 || window.msRequestAnimationFrame
 || function(f){setTimeout(f, 1000/60)}
 
var bkg = document.getElementsByClassName('lead-image')
 
function parallax(){
 var scrolltop = window.pageYOffset // get number of pixels document has scrolled vertically 

    var i = bkg.length;
    //console.log(i);
    while(i--) {
        bkg[i].style.webkitTransform = "translateY(" + scrolltop * .3 + "px)" // move at 33% of scroll rate
        bkg[i].style.MozTransform = "translateY(" + scrolltop * .3 + "px)"
        bkg[i].style.msTransform = "translateY(" + scrolltop * .3 + "px)"
        bkg[i].style.OTransform = "translateY(" + scrolltop * .3 + "px)"
        bkg[i].style.transform = "translateY(" + scrolltop * .3 + "px)"
    }
}
 
window.addEventListener('scroll', function(){ // on page scroll
    if (document.documentElement.clientWidth > 786) {
        requestAnimationFrame(parallax) // call parallax() on next available screen paint
    }
}, false)




})(jQuery);
